iframe {
  display: none;
}

:root {
  --amplify-primary-color: #43b0f1;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #1e3d58;
}

amplify-s3-image {
  --height: 100%;
  --width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
